import { createChart } from "lightweight-charts";
import { forwardRef, useEffect, useRef } from "react";

const Overlay = forwardRef(({ data, indicator, changeVisibleParentHandler, visibleParent, isShowDetails }, ref) => {
    const chartContainerRef = useRef();
    const chartRef = useRef();
    const candlestickSeriesRef = useRef();
    const lineSeriesRefs = useRef({});

    useEffect(() => {
        chartRef?.current?.timeScale().setVisibleRange({ from: visibleParent.from, to: visibleParent.to });
    }, [visibleParent.from])

    useEffect(() => {
        window?.addEventListener("resize", resizeChartHandler);
        return () => window?.removeEventListener("resize", resizeChartHandler);
    }, []);

    useEffect(() => {
        resizeChartHandler();
    }, [isShowDetails]);

    const resizeChartHandler = () => {
        if (chartRef.current) {
            chartRef.current.applyOptions({
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                timeScale: {
                    visible: false,
                    timeVisible: false,
                    secondsVisible: false
                },
            });
        }
    };

    useEffect(() => {
        if (!data || data.length === 0) return;
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            crosshair: {
                // hide the horizontal crosshair line
                horzLine: {
                    visible: false,
                    labelVisible: false,
                },
            },
            // hide the grid lines
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
        });

        const handleVisibleRangeChange = () => {
            const visibleRange = chartRef.current.timeScale().getVisibleRange();
            changeVisibleParentHandler(visibleRange);
        };
        // chartRef.current.timeScale().subscribeVisibleTimeRangeChange(handleVisibleRangeChange);
        return () => {
            chartRef.current.timeScale().unsubscribeVisibleTimeRangeChange(handleVisibleRangeChange);
            chartRef.current.remove();
        };
    }, [data]);

    useEffect(() => {
        Object.entries(indicator).map(([name, lines], index) => (
            index == 1 && drawIndicatorHandler(name, lines)
        ))
    }, [indicator])

    const drawIndicatorHandler = (indicatorName, data) => {
        if (lineSeriesRefs.current[indicatorName]) {
            lineSeriesRefs.current[indicatorName].forEach(series => chartRef.current.removeSeries(series));
            delete lineSeriesRefs.current[indicatorName];
        } else {
            const newLines = data.map(line => {
                const convertedData = Object.entries(line.data).map(([time, value]) => ({
                    time: new Date(time).getTime() / 1000,
                    value: value
                }));

                const lineSeries = chartRef.current.addLineSeries({
                    color: line.color,
                    lineWidth: line.width,
                    title: line.name,
                });
                lineSeries.setData(convertedData);
                return lineSeries;
            });

            lineSeriesRefs.current[indicatorName] = newLines;
        }
    };

    return (
        <div ref={chartContainerRef} className="relative w-full h-full border-t-2 border-red-700 border-dashed">

        </div>
    );
});

export default Overlay;
