import { Switch } from "@headlessui/react";
import { createChart } from "lightweight-charts";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Overlay from "./Overlay";
import { formatDate } from "../../utils";

const Chart = forwardRef(({ data, indicator, isHideExits, toggleExitsHandler, onMarkerClick, toggleMarkersHandler, isShowMarkers, isShowDetails, filteredPositionHandler }, ref) => {
    const chartContainerRef = useRef();
    const chartRef = useRef();
    const candlestickSeriesRef = useRef();
    const lineSeriesRefs = useRef({});
    const [tooltip, setTooltip] = useState(null);
    const [visibleParent, setVisibleParent] = useState({ from: null, to: null });
    const [isUseCustomColors, setIsUseCustomColors] = useState(false); // New state for color toggle

    useEffect(() => {
        window?.addEventListener("resize", resizeChartHandler);
        return () => window?.removeEventListener("resize", resizeChartHandler);
    }, []);

    useEffect(() => {
        resizeChartHandler();
    }, [isShowDetails]);

    useEffect(() => {
        if (!data || data.length === 0) return;

        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            timeScale: {
                visible: true,
                timeVisible: true,
                secondsVisible: true
            }
        });

        candlestickSeriesRef.current = chartRef.current.addCandlestickSeries();

        const candlestickData = data.map(datapoint => {
            if (isUseCustomColors && datapoint.custom_color) {
                // Apply custom color
                return {
                    ...datapoint,
                    color: datapoint.custom_color,
                    borderColor: datapoint.custom_color,
                    wickColor: datapoint.custom_color,
                };
            } else {
                // Apply default colors based on candlestick direction
                const isBullish = datapoint.close > datapoint.open;
                return {
                    ...datapoint,
                    color: isBullish ? '#26a69a' : '#ef5350',
                    borderColor: isBullish ? '#26a69a' : '#ef5350',
                    wickColor: isBullish ? '#26a69a' : '#ef5350',
                };
            }
        });

        candlestickSeriesRef.current.setData(candlestickData);

        const handleCrosshairMove = (param) => {
            if (!param || !param.time || param.point.x < 0 || param.point.x > chartContainerRef.current.clientWidth || param.point.y < 0 || param.point.y > chartContainerRef.current.clientHeight) {
                setTooltip(null);
            } else {
                const toolTipWidth = 80;
                const toolTipHeight = 80;
                const toolTipMargin = 15;
                const y = param.point.y;
                let left = param.point.x + toolTipMargin;
                if (left > chartContainerRef.current.clientWidth - toolTipWidth) {
                    left = param.point.x - toolTipMargin - toolTipWidth;
                }

                let top = y + toolTipMargin;
                if (top > chartContainerRef.current.clientHeight - toolTipHeight) {
                    top = y - toolTipHeight - toolTipMargin;
                }

                setTooltip({
                    left,
                    top,
                    time: param.time,
                    ...data.find(c => c.time === param.time),
                    indicators: indicator
                        ? Object.fromEntries(
                            Object.entries(indicator).map(([name, lines]) => {
                                return [
                                    name,
                                    Object.fromEntries(
                                        lines.map(line => [
                                            line.name,
                                            line.data[new Date(param.time * 1000).toISOString()] || ''
                                        ])
                                    )
                                ];
                            })
                        )
                        : {},
                });
            }
        };

        const handleVisibleRangeChange = () => {
            const visibleRange = chartRef.current.timeScale().getVisibleRange();
            setVisibleParent(visibleRange);
        };

        chartRef.current.subscribeCrosshairMove(handleCrosshairMove);
        chartRef.current.subscribeClick(handleMarkerClick);
        chartRef.current.subscribeClick(filterCandleHandler);
        chartRef.current.timeScale().subscribeVisibleTimeRangeChange(handleVisibleRangeChange);

        return () => {
            chartRef.current.timeScale().unsubscribeVisibleTimeRangeChange(handleVisibleRangeChange);
            chartRef.current.unsubscribeCrosshairMove(handleCrosshairMove);
            chartRef.current.unsubscribeClick(handleMarkerClick);
            chartRef.current.unsubscribeClick(filterCandleHandler);
            chartRef.current.remove();
        };
    }, [data, isUseCustomColors]); // Dependency on isUseCustomColors

    const filterCandleHandler = ({ time }) => {
        const filterCandle = data.filter(candle => candle.time === time || candle.close_time === time)
        filteredPositionHandler({
            type: "filterCandle",
            title: `time / close time equal to ${formatDate(time)}`,
            data: filterCandle
        })
    }

    const resizeChartHandler = () => {
        if (chartRef.current) {
            chartRef.current.applyOptions({
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight
            });
        }
    };

    const handleMarkerClick = ({ time }) => {
        const clickedCandle = data.find(candle => candle.time === time);
        onMarkerClick(clickedCandle);
    };

    const drawHorizontalLineBetweenCandles = ({ startTime, endTime, price, color, lineWidth, lineStyle, label }) => {
        const startIndex = data.findIndex(candle => candle.time === startTime);
        const endIndex = data.findIndex(candle => candle.time === endTime);

        if (startIndex !== -1 && endIndex !== -1 && startIndex !== endIndex) {
            const start = Math.min(startIndex, endIndex);
            const end = Math.max(startIndex, endIndex);
            const lineData = [];

            for (let i = start + 1; i <= end; i++) {
                lineData.push({ time: data[i].time, value: price });
            }

            const lineSeries = chartRef.current.addLineSeries({ color, title: label, lineWidth, lineStyle });
            lineSeries.setData(lineData);

            if (!lineSeriesRefs.current[startTime]) {
                lineSeriesRefs.current[startTime] = [];
            }
            lineSeriesRefs.current[startTime].push(lineSeries);
        }
    };

    const removeLinesBetweenCandles = () => {
        Object.values(lineSeriesRefs.current).forEach(seriesArray => {
            seriesArray.forEach(lineSeries => {
                chartRef.current.removeSeries(lineSeries);
            });
        });
        lineSeriesRefs.current = {};
    };

    const setVisibleRange = ({ from, to }) => {
        const visibleRange = chartRef.current.timeScale().getVisibleRange();
        // if (visibleRange) {
        //     const { from: currentFrom, to: currentTo } = visibleRange;

        //     // Filter the data to find candles in the current visible range
        //     const candlesInRange = data.filter(candle => candle.time >= currentFrom && candle.time <= currentTo);
        //     if (candlesInRange.find(candle => candle.time === from || to)?.id)
        //         return



        // }
        chartRef.current.timeScale().setVisibleRange({ from, to });
        setVisibleParent({ from, to })
    };

    const drawGraphLine = (lineData, { color, lineWidth, label }) => {
        const lineSeries = chartRef.current.addLineSeries({
            color,
            lineWidth,
            title: label,
        });
        lineSeries.setData(lineData);

        if (!lineSeriesRefs.current[label]) {
            lineSeriesRefs.current[label] = [];
        }
        lineSeriesRefs.current[label].push(lineSeries);
    };

    const drawIndicatorHandler = (indicatorName, data) => {
        if (lineSeriesRefs.current[indicatorName]) {
            lineSeriesRefs.current[indicatorName].forEach(series => chartRef.current.removeSeries(series));
            delete lineSeriesRefs.current[indicatorName];
        } else {
            const newLines = data.map(line => {
                const convertedData = Object.entries(line.data).map(([time, value]) => ({
                    time: new Date(time).getTime() / 1000,
                    value: value
                }));

                const lineSeries = chartRef.current.addLineSeries({
                    color: line.color,
                    lineWidth: line.width,
                    title: line.name,
                });
                lineSeries.setData(convertedData);
                return lineSeries;
            });

            lineSeriesRefs.current[indicatorName] = newLines;
        }
    };

    useImperativeHandle(ref, () => ({
        drawHorizontalLineBetweenCandles,
        removeLinesBetweenCandles,
        setVisibleRange,
        drawGraphLine,
        setMarkers: (markers) => candlestickSeriesRef.current.setMarkers(markers),
        getChart: () => chartRef.current,
    }));

    return (
        <div ref={chartContainerRef} className="relative w-full h-full">
            <div className="absolute z-50 w-full border right-1 opacity-80 bottom-5 h-28">
                <Overlay visibleParent={visibleParent}
                    data={data}
                    changeVisibleParentHandler={setVisibleRange}
                    onMarkerClick={onMarkerClick}
                    isShowDetails={isShowDetails}
                    indicator={indicator}
                    toggleMarkersHandler={toggleMarkersHandler}
                    isShowMarkers={isShowMarkers} />
            </div>
            <div className="absolute z-50 flex items-center justify-between top-3 left-4 right-20">
                <div>
                    <div className="flex items-center gap-4">
                        <span className="text-base text-neutral-950">Price</span>
                        {tooltip && <span className="text-sm text-red-500">O {tooltip?.open}</span>}
                        {tooltip && <span className="text-sm text-red-500">H {tooltip?.high}</span>}
                        {tooltip && <span className="text-sm text-red-500">L {tooltip?.low}</span>}
                        {tooltip && <span className="text-sm text-red-500">C {tooltip?.close}</span>}
                        {tooltip && <span className="text-sm text-red-500">{tooltip?.candle_value_change}</span>}
                        {tooltip && <span className="text-sm text-red-500">({tooltip?.candle_pct_change}%)</span>}
                    </div>
                    {indicator && Object.entries(indicator).map(([name, lines]) => (
                        <div key={name} className="flex items-center gap-4 mt-2">
                            <span className="text-base text-neutral-950">{name}</span>
                            <span className="text-base cursor-pointer text-neutral-800" onClick={() => drawIndicatorHandler(name, lines)}>👁</span>
                            {lines.map((line) => (
                                <span key={line.name} style={{ color: line.color }} className="text-sm">{tooltip?.indicators?.[name]?.[line.name]}</span>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                        <Switch
                            checked={isShowMarkers}
                            onChange={toggleMarkersHandler}
                            className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600">
                            <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                        </Switch>
                        All Positions
                    </div>
                    <div className="flex items-center gap-2 mt-5">
                        <Switch
                            checked={isHideExits}
                            onChange={toggleExitsHandler}
                            className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600">
                            <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                        </Switch>
                        Hide Exits
                    </div>
                    <div className="flex items-center gap-2 mt-5">
                        <Switch
                            checked={isUseCustomColors}
                            onChange={() => setIsUseCustomColors(!isUseCustomColors)}
                            className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600">
                            <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                        </Switch>
                        Custom Colors
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Chart;
