import { createChart } from "lightweight-charts";
import { forwardRef, useEffect, useRef } from "react";

const OverView = forwardRef(({
    data,
    indicator,
    onMarkerClick,
    toggleMarkersHandler,
    isShowMarkers,
    isShowDetails
}, ref) => {
    const chartContainerRef = useRef();
    const chartRef = useRef();
    const baseLineRef = useRef();

    useEffect(() => {
        window.addEventListener("resize", resizeChartHandler);
        return () => window.removeEventListener("resize", resizeChartHandler);
    }, []);

    useEffect(() => {
        resizeChartHandler();
    }, [isShowDetails]);

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Create the chart
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            timeScale: {
                visible: true,
                timeVisible: true,
                secondsVisible: true
            }
        });

        // Add the baseline series
        baseLineRef.current = chartRef.current.addBaselineSeries({
            baseValue: { type: 'price', price: 1000 },
            topLineColor: 'rgba(38, 166, 154, 1)',
            topFillColor1: 'rgba(38, 166, 154, 0.28)',
            topFillColor2: 'rgba(38, 166, 154, 0.05)',
            bottomLineColor: 'rgba(239, 83, 80, 1)',
            bottomFillColor1: 'rgba(239, 83, 80, 0.05)',
            bottomFillColor2: 'rgba(239, 83, 80, 0.28)',
        });

        baseLineRef.current.setData(data.filter(item => item.net_profit_percent).map(item => {
            return {
                value: item.net_profit_percent,
                time: item.time
            }
        }));

        return () => {
            chartRef.current.remove();
        };
    }, [data]);

    const resizeChartHandler = () => {
        if (chartRef.current) {
            chartRef.current.applyOptions({
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight
            });
        }
    };

    return (
        <div ref={chartContainerRef} className="relative w-full h-full">
        </div>
    );
});

export default OverView;
