import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ chartData }) => {
    const [data, setData] = useState({
        series: chartData.data,
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            xaxis: {
                categories: chartData.categories,
                tickPlacement: 'on'
            },
            fill: {
                opacity: 1
            },
            colors: chartData.colors,
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    });

    return (
        <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
        </div>
    )
}

export default BarChart;