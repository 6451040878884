import { useRef, useState } from "react";
import { classNames, convertDate } from "../../utils";
import Chart from "./Chart";
import PositionsList from "./PositionsList";

const ListOfTrade = ({ data, indicator, isShowDetails, positions }) => {
    const [isShowMarkers, setIsShowMarkers] = useState(false);
    const [shownMarkers, setShownMarkers] = useState([]);
    const [shownLines, setShownLines] = useState([]);
    const [markersTable, setMarkersTable] = useState([])
    const [filterList, setFilterList] = useState([])
    const [isHideExits, setIsHideExits] = useState(false)
    const chartRef = useRef();

    const filteredPositionHandler = (value) => {
        if (value?.data.length === 1) {
            toggleMarkerHandler(value.data[0])
            toggleTPSLHandler(value.data[0])
        }

        setFilterList(prevState => {
            const index = prevState.findIndex(filter => filter.type === value.type)
            if (index >= 0)
                prevState.splice(index, 1)
            return [
                ...prevState,
                value
            ]
        })
    }

    const toggleMarkersHandler = () => {
        let isShow = !isShowMarkers
        setIsShowMarkers(isShow);
        if (!isShow) {
            setIsHideExits(false)
        }
        if (shownLines.length > 0) {
            setShownLines([]);
            chartRef.current.removeLinesBetweenCandles()
        }
        let uniqueMarkers = uniqueMarkersGenerator()

        if (!isShowMarkers) {
            setShownMarkers(uniqueMarkers);
            chartRef.current.setMarkers(uniqueMarkers);
        } else {
            setShownMarkers([]);
            chartRef.current.setMarkers([]);
        }
    };


    const uniqueMarkersGenerator = () => {
        const markers = positions.flatMap((position) => {
            return position.direction === "sell"
                ? [
                    {
                        time: position.time,
                        position: 'aboveBar',
                        color: '#ef5350',
                        shape: 'arrowDown',
                        text: position.id.toString(),
                        size: 0.5
                    },
                    {
                        time: position.close_time,
                        position: 'belowBar',
                        color: position.net_profit_percent > 0 ? '#26a69a' : '#ef5350',
                        shape: 'circle',
                        text: position.id.toString(),
                        size: 0.5
                    }
                ]
                : [
                    {
                        time: position.time,
                        position: 'belowBar',
                        color: '#26a69a',
                        shape: 'arrowUp',
                        text: position.id.toString(),
                        size: 0.5
                    },
                    {
                        time: position.close_time,
                        position: 'aboveBar',
                        color: position.net_profit_percent > 0 ? '#26a69a' : '#ef5350',
                        shape: 'circle',
                        text: position.id.toString(),
                        size: 0.5
                    }
                ]
        });

        markers.sort((a, b) => a.time - b.time);

        const uniqueMarkers = markers.reduce((unique, current) => {

            const findExistingMarker = (time, position, shape) => unique.find(item => item.time === time && item.position === position && item.shape === shape);

            const existingMarker = findExistingMarker(current.time, current.position, current.shape);

            if (!existingMarker) {
                unique.push(current);
            }

            return unique;
        }, []);

        return uniqueMarkers
    }

    const toggleMarkerHandler = (position) => {
        const updatedMarkers = []

        if (isShowMarkers) {
            toggleMarkersHandler();
        }
        if (shownMarkers.findIndex(c => c.time === position.time) < 0 && position.index_taken > 0) {
            if (position.direction === 'sell') {
                updatedMarkers.push({
                    time: position.time,
                    position: 'aboveBar',
                    color: '#ef5350',
                    shape: 'arrowDown',
                    text: position.id.toString(),
                    size: 0.5
                },);
                updatedMarkers.push({
                    time: position.close_time,
                    position: 'belowBar',
                    color: position.net_profit_percent > 0 ? '#26a69a' : '#ef5350',
                    shape: 'circle',
                    text: position.id.toString(),
                    size: 0.5
                });
            } else {
                updatedMarkers.push({
                    time: position.time,
                    position: 'belowBar',
                    color: '#26a69a',
                    shape: 'arrowUp',
                    text: position.id.toString(),
                });
                updatedMarkers.push({
                    time: position.close_time,
                    position: 'aboveBar',
                    color: position.net_profit_percent > 0 ? '#26a69a' : '#ef5350',
                    shape: 'circle',
                    text: position.id.toString(),
                });
            }
        }
        setShownMarkers(updatedMarkers)

        updatedMarkers.sort((a, b) => a.time - b.time);
        chartRef.current.setMarkers(updatedMarkers);
        chartRef.current.setVisibleRange({
            from: position.time,
            to: position.close_time,
        });
    };

    const toggleTPSLHandler = (candle) => {
        const { time, close_time, tp, sl, close, dict_sl } = candle;
        const existingIndex = shownLines.findIndex(line =>
            line.startTime === time && line.endTime === close_time
        );

        if (existingIndex !== -1) {
            setShownLines([]);
            chartRef.current.removeLinesBetweenCandles()
        } else {
            const tpLine = {
                startTime: time,
                endTime: close_time,
                price: tp,
                color: '#00FF00',
                lineWidth: 2,
                lineStyle: 0,
                label: 'TP'
            };

            const entryLine = {
                startTime: time,
                endTime: close_time,
                price: close,
                color: '#424a54',
                lineWidth: 2,
                lineStyle: 0,
                label: 'Entry'
            };

            const slLineData = Object.entries(dict_sl).map(([time, price]) => ({
                time: convertDate(time),
                value: price
            }));

            setShownLines([
                tpLine,
                entryLine
            ]);

            chartRef.current.removeLinesBetweenCandles()
            chartRef.current.drawHorizontalLineBetweenCandles(tpLine);
            chartRef.current.drawHorizontalLineBetweenCandles(entryLine);
            chartRef.current.drawGraphLine(slLineData, { color: '#FF0000', lineWidth: 2, label: 'SL' });


            chartRef.current.setVisibleRange({
                from: candle.time,
                to: candle.close_time,
            });
        }
    };

    const onMarkerClick = (markersData) => {
        setMarkersTable(markersData);
    };

    const removeFilterHandler = (filter) => {
        setFilterList(prevState => {
            return prevState.filter(item => item.type !== filter.type)
        })
    }

    const toggleExitsHandler = () => {
        if (!isShowMarkers)
            setIsShowMarkers(true)
        let isHide = !isHideExits
        setIsHideExits(isHide);
        if (shownLines.length > 0) {
            setShownLines([]);
            chartRef.current.removeLinesBetweenCandles()
        }
        let markers = uniqueMarkersGenerator()
        let filterMarkers = markers.filter(item => item.shape !== 'circle')
        setShownMarkers(isHide ? filterMarkers : markers);
        chartRef.current.setMarkers(isHide ? filterMarkers : markers);
    }

    return (
        <div className='flex w-full'>
            {isShowDetails && <div className='w-1/3 p-3 bg-neutral-100'>
                {filterList.map((filter) => {
                    return <span className="flex items-center gap-1 px-2 py-1 mb-2 text-sm rounded-full cursor-pointer bg-neutral-200 text-neutral-500 w-fit"
                        onClick={() => removeFilterHandler(filter)}>{filter.title}
                        <div className="flex items-center justify-center text-center text-white align-middle rounded-full text-2xs size-4 bg-neutral-500">✘</div>
                    </span>
                })}
                <PositionsList
                    title="Data Grid"
                    data={positions}
                    shownMarkers={shownMarkers}
                    filterList={filterList}
                    shownLines={shownLines}
                    onToggleMarker={toggleMarkerHandler}
                    onToggleTPSL={toggleTPSLHandler}
                />
            </div>}
            <div className={classNames('border-l-4 border-neutral-300', isShowDetails ? "w-2/3" : "w-full")}>
                <Chart ref={chartRef}
                    data={data}
                    isHideExits={isHideExits}
                    toggleExitsHandler={toggleExitsHandler}
                    onMarkerClick={onMarkerClick}
                    isShowDetails={isShowDetails}
                    filteredPositionHandler={filteredPositionHandler}
                    indicator={indicator}
                    toggleMarkersHandler={toggleMarkersHandler}
                    isShowMarkers={isShowMarkers} />
            </div>
        </div>
    )
}

export default ListOfTrade