import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react'
import { convertDate, formatDate } from '../../utils';

const FilterModal = ({ items, onChangeHandler, getFilterListHandler }) => {

    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState(items);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: key === "direction"
                ? {
                    ...prev[key],
                    value: value || '',
                    operator: "equal"
                }
                : {
                    ...prev[key],
                    value: value || '',
                }
        }));
    };

    const handleOperatorChange = (key, operator) => {
        setFilters(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                operator: operator
            }
        }));
    };

    const applyFilters = () => {
        const filtered = items.filter(item => {
            console.log(item);

            return Object.keys(filters).every(key => {
                const { value, operator } = filters[key] || {};

                if (value === '') return true;
                if (operator === 'equal') return item[key] === value;
                if (operator === 'bigger') return item[key] > value;
                if (operator === 'smaller') return item[key] < value;
                return true;
            });
        });
        console.log(filtered);

        setFilteredData(filtered);
        getFilterListHandler(filters);
        onChangeHandler(filtered)
    };

    const removeFilter = (key) => {
        const { [key]: _, ...rest } = filters;
        setFilters(rest);
        applyFilters();
    };

    const clearFilters = () => {
        setFilters({});
        setFilteredData(items);
        onChangeHandler(items)
        getFilterListHandler(null);
        handleClose()
    };

    return <div className="">
        <Button variant="contained" color="primary" onClick={handleOpen}>
            Open Filter Modal
        </Button>

        <div className="mt-5">
            <div className="flex flex-wrap gap-2 mt-5">
                {Object.keys(filters).map((key) => (
                    <Chip
                        key={key}
                        label={`${key} ${filters[key]?.operator || ''} ${key === "time" || "close_time" ? formatDate(filters[key]?.value) : filters[key]?.value || ''}`}
                        onDelete={() => removeFilter(key)}
                    />
                ))}
            </div>
        </div>

        <Modal className='overflow-auto'
            open={open}
            onClose={handleClose}
            aria-labelledby="filter-modal-title"
            aria-describedby="filter-modal-description"
        >
            <Box className="w-8/12 p-5 m-5 bg-white rounded-md shadow-lg">
                <h2 id="filter-modal-title" className="mb-4 text-xl font-semibold">
                    Filter Data
                </h2>

                {/* Filter Inputs */}
                {Object.keys(items[0]).map((key) => {
                    switch (key) {
                        case "time":
                        case "close_time":
                            return <>
                                <div key={key} className="flex m-1">
                                    <FormControl fullWidth>
                                        <InputLabel>{`Select ${key} Filter`}</InputLabel>
                                        <Select className='mr-2'
                                            value={filters[key]?.operator || ''}
                                            label={`Select ${key} Filter`}
                                            onChange={(e) => handleOperatorChange(key, e.target.value)}
                                        >
                                            <MenuItem value="equal">Equal To</MenuItem>
                                            <MenuItem value="bigger">Bigger Than</MenuItem>
                                            <MenuItem value="smaller">Smaller Than</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            label={key}
                                            fullWidth
                                            value={(filters[key]?.value && filters[key]?.value.length > 0) ? formatDate(filters[key]?.value) : ''}
                                            onChange={(date) => {
                                                console.log(convertDate(date));

                                                handleInputChange(key, convertDate(date))
                                            }}
                                            inputFormat="MM/dd/yyyy HH:mm"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </>
                        case "gross_profit_percent":
                        case "candle_pct_change":
                        case "net_profit_percent":
                            return <>
                                <div key={key} className="flex m-1">
                                    <FormControl fullWidth>
                                        <InputLabel>{`Select ${key} Filter`}</InputLabel>
                                        <Select className='mr-2'
                                            value={filters[key]?.operator || ''}
                                            label={`Select ${key} Filter`}
                                            onChange={(e) => handleOperatorChange(key, e.target.value)}
                                        >
                                            <MenuItem value="equal">Equal To</MenuItem>
                                            <MenuItem value="bigger">Bigger Than</MenuItem>
                                            <MenuItem value="smaller">Smaller Than</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        type="number"
                                        label={key}
                                        InputProps={{ endAdornment: "%" }}
                                        value={filters[key]?.value || ''}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        fullWidth
                                        className="mt-2"
                                    />
                                </div>
                            </>
                        case "market":
                        case "dict_sl":
                        case "showMarker":
                        case "custom_color":
                        case "costum_color":
                        case "showTPSL":
                            break
                        case "direction":
                            return <>
                                <div key={key} className="flex m-1">
                                    <FormControl fullWidth>
                                        <InputLabel>{`Select ${key} Filter`}</InputLabel>
                                        <Select value={filters[key]?.value || ''}
                                            label={`Select ${key} Filter`}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                        >
                                            <MenuItem value="BUY">BUY</MenuItem>
                                            <MenuItem value="Sell">Sell</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </>

                        default:
                            return <div key={key} className="flex m-1">
                                <FormControl fullWidth>
                                    <InputLabel>{`Select ${key} Filter`}</InputLabel>
                                    <Select className='mr-2'
                                        value={filters[key]?.operator || ''}
                                        label={`Select ${key} Filter`}
                                        onChange={(e) => handleOperatorChange(key, e.target.value)}
                                    >
                                        <MenuItem value="equal">Equal To</MenuItem>
                                        <MenuItem value="bigger">Bigger Than</MenuItem>
                                        <MenuItem value="smaller">Smaller Than</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="number"
                                    label={key}
                                    value={filters[key]?.value || ''}
                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                    fullWidth
                                    className="mt-2"
                                />
                            </div>
                    }
                })}

                <div className='flex justify-between mt-5'>
                    <Button
                        variant="contained"
                        onClick={() => {
                            applyFilters();
                            handleClose();
                        }}
                        color="primary"
                    >
                        Apply Filters
                    </Button>

                    <Button
                        variant="outlined"
                        color="error"
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </Button>
                </div>
            </Box>
        </Modal>
    </div>
}
export default FilterModal


const DateTimeFilter = ({ label, value, onChange }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label={label}
            value={value}
            onChange={(date) => onChange(date)}
            inputFormat="MM/dd/yyyy HH:mm"
        />
    </LocalizationProvider>
);