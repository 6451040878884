import { useState } from "react";
import { classNames } from "../utils"

const MainLayout = ({ children, changeTabHandler, showDetailsHandler, activeTab }) => {
    return (
        <div className='grid grid-rows-[50px_1fr] h-screen'>
            <div className='flex items-center justify-between w-full h-12 bg-white border-b-4 border-neutral-300'>
                <div className='flex items-center h-full gap-10 px-4'>
                    <p className='text-base font-semibold text-neutral-800'>Crypto</p>
                    <div className='flex self-end gap-6 -mb-1'>
                        <div onClick={() => changeTabHandler("data")}
                            className={classNames("text-sm pb-2 cursor-pointer",
                                activeTab === "data" ? "text-neutral-800 border-b-4 font-bold  border-pink-700" : "text-neutral-500")}>
                            List Of Trade
                        </div>
                        <div onClick={() => changeTabHandler("overview")}
                            className={classNames("text-sm pb-2 cursor-pointer",
                                activeTab === "overview" ? "text-neutral-800 border-b-4 font-bold  border-pink-700" : "text-neutral-500")}>
                            Overview
                        </div>
                        <div onClick={() => changeTabHandler("summary")}
                            className={classNames("text-sm pb-2 cursor-pointer",
                                activeTab === "summary" ? "text-neutral-800 border-b-4 font-bold  border-pink-700" : "text-neutral-500")}>
                            Summary
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between overflow-hidden'>
                <div className='px-1 py-4 bg-white border-r w-14 border-neutral-300'>
                    <i onClick={showDetailsHandler} className='p-3 text-2xl rounded cursor-pointer mb-icon__icon-me__hamburger-menu--outline text-neutral-600 bg-neutral-100' />
                </div>
                {children}
            </div>
        </div>
    )
}

export default MainLayout