import React, { useState } from 'react';
import './App.css';
import "./assets/fontIcons/style.scss";
import FileUploaderModal from './FileUploaderModal';
import MainLayout from './layout/MainLayout';
import Summary from './pages/summary/Summary';
import ListOfTrade from "./pages/listOfTrade/ListOfTrade"
import { convertDate } from './utils';
import OverView from './pages/overview/OverView';

function App() {
	const [indicator, setIndicator] = useState({});
	const [data, setData] = useState([]);
	const [summaryData, setSummaryData] = useState({});
	const [activeTab, setActiveTab] = useState('data');
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [positions, setPositions] = useState([]);
	const [isShowDetails, setIsShowDetails] = useState(true);

	// useEffect(() => {
	// 	handleFileUpload(JSON.stringify(test))
	// }, [])

	const handleFileUpload = (fileContent) => {
		const parsedData = JSON.parse(fileContent);
		const convertedCandles = parsedData.candles.map(file => ({
			...file,
			time: convertDate(file.time),
			close_time: file.close_time ? convertDate(file.close_time) : null,
			showMarker: false,
			showTPSL: false,
		}));
		setData(convertedCandles);
		setIndicator(parsedData.indicator)
		setPositions(convertedCandles.filter(candle => candle.close_time))
		setIsModalOpen(false)
		setSummaryData(parsedData.summary);
	};

	const changeTabHandler = (value) => setActiveTab(value);

	const showDetailsHandler = () => setIsShowDetails(!isShowDetails);

	const tabGenerator = () => {
		switch (activeTab) {
			case "data":
				return <ListOfTrade data={data} indicator={indicator} isShowDetails={isShowDetails} positions={positions} />
			case "summary":
				return <Summary isShowDetails={isShowDetails} summaryData={summaryData} />
			case "overview":
				return <OverView data={data} indicator={indicator} isShowDetails={isShowDetails} positions={positions} />
			default:
				return <></>
		}
	}

	return (
		<>
			{isModalOpen
				? <FileUploaderModal
					isOpen={isModalOpen}
					onFileUpload={handleFileUpload}
				/> : <MainLayout showDetailsHandler={showDetailsHandler} changeTabHandler={changeTabHandler} activeTab={activeTab}>
					{tabGenerator()}
				</MainLayout>
			}</>
	);
}

export default App;