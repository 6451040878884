import { classNames } from "../../utils"
import BarChart from "./BarChart";
import SummaryGrid from "./SummaryGrid"

const Summary = ({ isShowDetails, summaryData }) => {
    return (
        <div className='flex w-full'>
            {isShowDetails && <div className='w-1/3 p-3 bg-neutral-100'>
                <SummaryGrid summary={summaryData.detail} />
            </div>}
            <div className={classNames('border-l-4 border-neutral-300', isShowDetails ? "w-2/3" : "w-full")}>
                <BarChart chartData={summaryData.columnChart} />
            </div>
        </div>
    )
}

export default Summary;