import React from 'react';
import './App.css';

function FileUploaderModal({ isOpen, onFileUpload }) {
    if (!isOpen) return null;

    const handleChange = (e) => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            onFileUpload(e.target.result);
        };
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Upload a File</h2>
                <input type="file" onChange={handleChange} className="file-input" />
            </div>
        </div>
    );
}

export default FileUploaderModal;
