import { classNames } from "../../utils";

const SummaryGrid = ({ summary }) => {
    return (
        <div className="summary-grid-container overflow-auto h-[calc(100vh-75px)]">
            <div className="summary-grid">
                {summary?.map((item, index) => {
                    return <div key={index} className='grid w-full grid-cols-2 bg-white border whitespace-nowrap border-neutral-300 divide-2 divide-neutral-300'>
                        <div className='w-3/5 p-2 '>{item.title.replaceAll('_', ' ')}</div>
                        <div className={classNames('w-2/5 p-2 border-l-2 border-neutral-300',
                            item.color === 'red' && 'text-red-500',
                            item.color === 'green' && 'text-green-500',
                            item.color === 'black' && 'text-black')}>{item.value}</div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default SummaryGrid;