import React, { useEffect, useRef, useState } from 'react';
import { classNames, formatDate } from '../../utils';
import FilterModal from './FilterModal';
function PositionsList({ title, closeMarkersDataGridHandler, data, shownMarkers, shownLines, onToggleTPSL, onToggleMarker, filterList }) {

    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState({})
    const [hasMore, setHasMore] = useState(true);
    const cardListRef = useRef();

    useEffect(() => {
        loadMoreItems();
        cardListRef.current.addEventListener("scroll", handleScroll)
        return () => cardListRef.current?.removeEventListener("scroll", handleScroll)
    }, [])

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = cardListRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5, !filterList) {
            loadMoreItems()
        }
    }

    const loadMoreItems = () => {
        setItems(prevState => {
            if (prevState.length >= data.length) {
                setHasMore(false)
                return prevState
            } else {
                let newItems = data.slice(prevState.length, prevState.length + 10)
                return [...prevState, ...newItems]
            }
        })
    }

    // const handleToggleMarker = (candle) => {
    //     onToggleMarker(candle);
    // };

    // const handleToggleTPSL = (candle) => {
    //     onToggleTPSL(candle);
    // };
    const isMarkerShown = (time) => {
        return shownMarkers.some(m => m.time === time);
    };

    const isLineShown = (time, close_time) => {
        return shownLines.some(line => line.startTime === time && line.endTime === close_time);
    };

    const handleTogglePosition = (candle) => {
        if (candle.close_time) {
            onToggleMarker(candle);
            onToggleTPSL(candle);
        }
    }

    return <div className='flex flex-col gap-2 overflow-auto h-[calc(100vh-75px)]' ref={cardListRef}>
        <FilterModal items={data} onChangeHandler={(itemList) => setItems(itemList)} getFilterListHandler={(filterList) => setFilters(filterList)} />
        {(filterList.length > 0 ? filterList.flatMap(filter => filter.data) : items).map((candle, index) => {
            return <div key={index} className={classNames('p-2 rounded-xl border border-neutral-300 ',
                isMarkerShown(candle.time) ? "bg-neutral-300" : "bg-white", candle.close_time ? "cursor-pointer" : "cursor-not-allowed")} onClick={() => handleTogglePosition(candle)}>
                <div className='flex items-center justify-between'>
                    <div className={classNames('rounded-lg px-2 py-1 w-fit text-white text-sm', candle.net_profit_percent > 0 ? "bg-green-500" : "bg-red-500 ")}>
                        <span>{candle.net_profit_percent}% ({candle.net_profit_dollar}$)</span>
                    </div>
                    <div className={classNames('px-3 py-1 text-sm font-semibold rounded-lg w-fit', candle.direction === "sell" ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100')}>{candle.direction.toString().toUpperCase()}</div>
                </div>
                <div className='flex items-center gap-3 mt-2'>
                    <div className='text-2xs text-neutral-500 '>
                        Position ID: {candle.id}
                    </div>
                    <div className='text-2xs text-neutral-500 '>
                        Exchange:
                    </div>
                    <div className='text-2xs text-neutral-500 '>
                        Market: {candle.market}
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-2 p-2 mt-1 rounded-lg bg-neutral-100'>
                    <div className='text-xs text-neutral-800'>
                        Size: <span className='font-bold'>{candle.volume} BTC ({candle.dollar_size_volume}$)</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Stop Loss: <span className='font-bold'>{candle.sl}</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Candle in Position: <span className='font-bold'>{candle.index_taken}</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Take Profit: <span className='font-bold'>{candle.tp}</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Drawdown: <span className='font-bold'>{candle.draw_down} %</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Run-up: <span className='font-bold'>{candle.run_up} %</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Risk to Reward: <span className='font-bold'>{candle.risk_to_reward}</span>
                    </div>
                    <div className='text-xs text-neutral-800'>
                        Run-up to profit: <span className='font-bold'>{candle.run_up_to_profit_ratio}</span>
                    </div>
                </div>
                <div className='flex gap-2 mt-1'>
                    <div className='w-1/2'>
                        <p className='text-sm font-semibold text-neutral-400'>Open</p>
                        <div className='grid grid-cols-1 gap-2 p-2 mt-1 rounded-lg bg-neutral-100'>
                            <div className='text-xs text-neutral-800'>
                                Price: <span className='font-bold'>{candle.close}</span>
                            </div>
                            <div className='text-xs text-neutral-800'>
                                Fee: <span className='font-bold'>{candle.fee_open_dollar} $ ({candle.fee_open_bit} BTC)</span>
                            </div>
                            <div className='text-xs text-neutral-800'>
                                Time: <span className='font-bold'>{formatDate(candle.time)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <p className='text-sm font-semibold text-neutral-400'>Close</p>
                        <div className='grid grid-cols-1 gap-2 p-2 mt-1 rounded-lg bg-neutral-100'>
                            <div className='text-xs text-neutral-800'>
                                Price: <span className='font-bold'>{candle.close_price}</span>
                            </div>
                            <div className='text-xs text-neutral-800'>
                                Fee: <span className='font-bold'>{candle.fee_close_dollar} $ ({candle.fee_close_bit} BTC)</span>
                            </div>
                            <div className='text-xs text-neutral-800'>
                                Time: <span className='font-bold'>{formatDate(candle.close_time)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </div>
}

export default PositionsList;
